$indentation: 20vh;

.login {
  display: flex;
  justify-content: center;
  margin: $indentation;

  &__form {
    display: flex;
    flex-direction: column;
    width: 300px;
  }

  &__button {
    padding: 5px 0;
    border-radius: 5px;
    margin: 5px;
    background-color: #17155c;
    color: #fff;
  }

  &__input {
    padding: 5px 0 5px 15px;
    margin: 5px;
    border-radius: 5px;
    border: 2px solid black;
  }
}

@media screen and (max-width: 575.98px) {
  .header {
    justify-content: space-between;
  }
}
