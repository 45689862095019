// background-color for descriotion
$bgcolor: #efedcf;

.events {
  margin: 0 auto;
  display: flex;
  width: 1100px;
  flex-direction: column;
  justify-content: center;
  animation: filt 1s forwards;

  &__filter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 0 0;
    font-size: 20px;
  }

  &__list {
    display: grid;
    gap: 40px;
    grid-template-areas: ". . . .";
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    animation: events 1s forwards;
    padding-top: 10vh;
  }

  &__item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 250px;
    // margin: 25px;
    background-color: #efedcf;
    border-radius: 5px;
    border: 1px solid black;
  }

  &__name {
    padding: 10px 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 20px;
    text-align: center;
    background-color: #fff;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  &__description {
    padding: 10px 5px;
    background-color: $bgcolor;
    height: 100px;
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    position: relative;
  }

  &__description:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2.4em;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      $bgcolor 96%
    );
    pointer-events: none;
  }

  &__date {
    text-align: center;
    padding: 10px 0 5px 0;
  }

  &__status {
    position: relative;
    text-align: center;
    font-size: 15px;
    padding: 5px 0 10px 0;
    text-transform: uppercase;
    font-weight: 600;
  }

  &__delete-icon {
    position: absolute;
    cursor: pointer;
    width: 25px;
    left: 90%;
    transition: 0.3s;
    padding: 1px;
    top: -30%;
  }

  &__delete-icon:hover {
    fill: tomato;
  }

  &__checkbox {
    width: 20px;
    height: 20px;
    margin: 0 10px 0 2px;
  }

  &__filter:nth-child(even) {
    margin: 0;
  }

  &__checkbox:hover {
    cursor: pointer;
  }

  &__desc {
    margin-right: 10px;
  }

  &__empty {
    margin-top: $indentation;
    font-size: 20px;
  }

  &__name:first-letter,
  &__description:first-letter {
    text-transform: uppercase;
  }

  &__admin-menu {
    display: flex;
    justify-content: center;
    margin: 20px 0 0 0;
  }

  &__admin-button {
    background-color: #17155c;
    width: 300px;
    padding: 10px 25px;
    font-size: 15px;
    border-radius: 5px;
    transition: 0.2s;
    font-weight: 500;
    color: #fff;
  }

  &__admin-button:nth-child(-n + 2) {
    margin-right: 50px;
  }

  &__admin-button:hover {
    background-color: #29508e;
  }
}

@keyframes events {
  0% {
    margin-top: 500px;
  }
  100% {
    margin-top: 0;
    opacity: 100%;
  }
}

@keyframes filt {
  0% {
    opacity: 10%;
  }
  100% {
    opacity: 100%;
  }
}

@media screen and (max-width: 1199.98px) {
  .events {
    width: 1000px;

    &__list {
      width: 1000px;
      grid-template-areas: ". . .";
      gap: 15px;
    }

    &__status {
      font-size: 12px;
    }

    &__delete-icon {
      top: -50%;
      left: 90%;
    }

    &__item {
      margin: 20px;
      width: 230px;
    }
  }
}

@media screen and (max-width: 991.98px) {
  .events {
    width: 840px;

    &__list {
      width: 840px;
    }

    &__admin-menu {
      flex-direction: column;
      align-items: center;
      margin: 30px;
    }

    &__admin-button {
      padding: 10px 15px;
    }

    &__admin-button:nth-child(-n + 2) {
      margin-right: 0px;
      margin-bottom: 10px;
    }

    &__item {
      margin: 10px;
      width: 202px;
    }

    &__delete-icon {
      left: 87%;
    }

    &__description {
      max-height: 75px;
    }
  }
}

@media screen and (max-width: 767.98px) {
  .events {
    width: 690px;

    &__checkbox {
      width: 12px;
    }

    &__list {
      width: 690px;
      grid-template-areas: ". .";
    }

    &__filter label,
    &__desc {
      font-size: 15px;
    }

    &__admin-button {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 575.98px) {
  .events {
    width: 480px;

    &__list {
      padding-top: 5vh;
      width: 480px;
      gap: 2px;
    }
  }
}

@media screen and (max-width: 420px) {
  .events {
    width: 400px;
    &__list {
      width: 400px;
      grid-template-areas: ".";
    }
  }
}
