.stats {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 50px 0 50px 0;
  width: 1000px;
  font-family: "Open Sans", sans-serif;
  align-items: center;
  justify-content: center;

  &__title {
    font-size: 20px;
    margin: 0 0 10px 0;
  }

  &__table {
    text-align: left;
  }

  &__status {
    margin: 10px 0;
  }

  &__edit-event {
    display: flex;
    flex-direction: column;
  }
}

table,
td,
th {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}

@media screen and (max-width: 991.98px) {
  .stats {
    width: 840px;
  }
}

@media screen and (max-width: 767.98px) {
  .stats {
    width: 690px;
  }
}

@media screen and (max-width: 575.98px) {
  .stats {
    width: 480px;
  }

  th {
    padding: 2px;
  }
}

@media screen and (max-width: 420px) {
  .stats {
    width: 400px;
  }
}
