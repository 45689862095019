// width for inputs and t/a
$text-width: 500px;

// mixin for inputs and t/a
@mixin marginElem {
  margin: 5px 0 20px 0;
  padding: 5px 0 5px 5px;
  border-radius: 5px;
  border: 1px solid black;
  width: $text-width;
}

.create-event {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: create-event 1s forwards;
  padding-top: 10vh;

  &__title {
    margin: 30px 0;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__name,
  &__fullNameOfTheCandidate,
  &__academicDegree {
    @include marginElem();
  }

  &__description {
    min-height: 120px;
    max-height: 200px;
    resize: vertical;

    @include marginElem();
  }

  &__numberOfVotes {
    @include marginElem();
  }

  &__peoples {
    display: flex;
    margin: 20px 0;
    justify-content: space-between;
    align-items: center;
    width: $text-width;
  }

  &__left,
  &__right {
    display: flex;
    flex-direction: column;
  }

  &__title-box {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &__right {
    margin-top: 30px;
  }

  &__clue {
    color: tomato;
    font-size: 13px;
    margin: 0 0 20px 0;
  }

  &__item {
    padding: 5px 0;
    cursor: pointer;
    border: 1px solid;
    border-color: transparent;
  }

  &__item:hover {
    border: 1px solid red;
  }

  &__item:nth-child(odd) {
    background-color: #ccc;
  }

  &__selected,
  &__all {
    border: 1px solid black;
    margin-top: 5px;
    max-height: 200px;
    min-height: 200px;
    overflow-y: scroll;
  }

  &__buttons {
    display: flex;
    width: $text-width;
    justify-content: center;
    margin-bottom: 20px;
  }

  &__button,
  &__print-button {
    background-color: #17155c;
    margin-bottom: 15px;
    padding: 10px 25px;
    font-size: 15px;
    border-radius: 5px;
    transition: 0.2s;
    font-weight: 500;
    color: #fff;
  }

  &__button:hover,
  &__print-button:hover {
    background-color: #29508e;
  }

  &__date {
    border: 1px solid black;
    padding: 5px;
  }

  &__members-of-commission {
    margin-left: 15px;
    width: 15px;
    height: 15px;
  }

  &__subtitle-members {
    width: 220px;
    text-align: center;
    margin-top: 5px;
  }
}

.create-event label {
  font-size: 15px;
}

.create-event ul {
  list-style: none;
  width: $text-width / 2.2;
  border-radius: 5px;
}

@keyframes create-event {
  0% {
    margin-top: 500px;
    opacity: 10%;
  }

  100% {
    margin-top: 0;
    opacity: 100%;
  }
}

@media screen and (max-width: 1199.98px) {
  .create-event {
    &__title {
      font-size: 25px;
    }
  }
}

@media screen and (max-width: 767.98px) {
  $text-width: 400px;

  .create-event {
    &__button {
      font-size: 12px;
    }

    &__title {
      font-size: 20px;
    }

    &__peoples,
    &__name,
    &__buttons,
    &__description,
    &__numberOfVotes,
    &__fullNameOfTheCandidate,
    &__academicDegree {
      width: $text-width;
    }
  }

  .create-event ul {
    list-style: none;
    width: $text-width / 2.2;
    border-radius: 5px;
  }
}
