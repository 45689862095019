.managing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: create-event 1s forwards;
  padding-top: 10vh;

  &__title {
    margin: 30px 0;
  }

  &__list {
    list-style: none;
    width: 500px;
    border: 1px solid black;
    border-bottom: none;
    text-align: center;
  }

  &__item {
    background-color: #fff;
    transition: 0.3s;
  }

  &__item:nth-child(odd) {
    background-color: #ccc;
  }

  &__item:hover {
    background-color: #7fcd51;
    cursor: pointer;
  }

  &__edit,
  &__delete {
    transition: 0.3s;
  }

  &__edit:hover {
    background-color: #17155c;
    color: #fff;
  }

  &__delete:hover {
    background-color: tomato;
  }
}

@media screen and (max-width: 1199.98px) {
  .managing {
    &__title {
      font-size: 25px;
    }
  }
}

@media screen and (max-width: 767.98px) {
  .managing {
    &__title {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 575.98px) {
  .managing {
    &__list {
      width: 440px;
    }
  }
}
