.header {
  position: relative;
  display: flex;
  background-color: #07053f;
  justify-content: space-evenly;
  align-items: center;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  height: 180px;

  &__back-item {
    position: absolute;
    top: 80%;
    left: 43%;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    background-color: inherit;
  }

  &__back-item_forward {
    left: 53%;
    position: absolute;
    top: 80%;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    background-color: inherit;
  }

  &__menu {
    display: flex;
  }

  &__menu-item {
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
  }

  &__menu-item:nth-child(-n + 1) {
    margin-right: 10px;
  }

  &__logo {
    width: 100px;
    cursor: pointer;
  }

  &__name {
    text-align: center;
  }

  &__title {
    text-transform: uppercase;
    font-weight: 600;
    padding-bottom: 10px;
    font-size: 30px;
  }

  &__subtitle {
    font-size: 25px;
  }

  &__stub {
    width: 112.387px;
  }
}

@media screen and (max-width: 1199.98px) {
  .header {
    &__title {
      font-size: 25px;
    }

    &__subtitle {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 767.98px) {
  .header {
    &__logo {
      width: 70px;
    }

    &__title {
      font-size: 20px;
    }

    &__subtitle {
      font-size: 15px;
    }

    &__back-item {
      left: 20%;
    }

    &__back-item_forward {
      left: 70%;
    }

    &__menu-item {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 575.98px) {
  .header {
    &__menu {
      flex-direction: column-reverse;
    }

    &__menu-item:nth-child(-n + 1) {
      margin-top: 30px;
    }

    &__subtitle {
      width: 280px;
      line-height: 20px;
    }
  }
}
