.create-user {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  animation: create-event 1s forwards;
  padding-top: 10vh;

  &__info {
    position: relative;
  }

  &__title {
    margin: 30px 0;
  }

  &__login,
  &__password,
  &__repeatPassword,
  &__fullName {
    margin: 5px 0 20px 0;
    padding: 5px 0 5px 5px;
    border-radius: 5px;
    border: 1px solid black;
    width: 500px;
  }

  &__buttons {
    display: flex;
    width: $text-width;
    justify-content: center;
    margin: 20px 0;
  }

  &__button {
    background-color: #17155c;
    padding: 10px 25px;
    font-size: 15px;
    border-radius: 5px;
    transition: 0.2s;
    font-weight: 500;
    color: #fff;
  }

  &__button:hover {
    background-color: #29508e;
  }

  &__icon {
    position: absolute;
    cursor: pointer;
  }

  &__clue {
    font-size: 13px;
  }

  &__notice {
    display: flex;
    flex-direction: column;
  }
}

.create-user label {
  font-size: 15px;
}

.password-box {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1199.98px) {
  .create-user {
    &__title {
      font-size: 25px;
    }
  }
}

@media screen and (max-width: 767.98px) {
  .create-user {
    &__title {
      font-size: 20px;
    }

    &__login,
    &__password,
    &__repeatPassword {
      width: 400px;
    }

    &__buttons {
      width: 400px;
    }
  }
}
