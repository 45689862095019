// IMPORT FONTS
@import "fonts";
// ============================================

// IMPORT RESET STYLES
@import "reset.scss";
// ============================================

// IMPORT STYLES
@import "header.scss";
@import "login-page.scss";
@import "events-list.scss";
@import "current-event.scss";
@import "accepted-vote.scss";
@import "stats.scss";
@import "create-event.scss";
@import "create-user.scss";
@import "success.scss";
@import "confirm-action.scss";
@import "managing.scss";
@import "loader.scss";
@import "edit-user.scss";
@import "print-page.scss";
@import "tooltip.scss";
// ============================================

body {
  font-family: "Open Sans", sans-serif;
}

button:disabled,
input:disabled,
textarea:disabled {
  opacity: 40%;
  cursor: not-allowed;
}
