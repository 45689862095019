.current-event {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  font-weight: 500;
  width: 1000px;
  animation: events 1s forwards;
  padding-top: 10vh;

  &__name {
    margin: 20px 0 0 0;
    font-size: 25px;
    text-align: center;
  }

  &__name:first-letter,
  &__description:first-letter {
    text-transform: uppercase;
  }

  &__description {
    margin: 50px 0 20px 0;
    font-size: 20px;
    text-align: center;
  }

  &__closed {
    text-align: center;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  &__button {
    width: 260px;
    padding: 20px 35px;
    font-size: 15px;
    border-radius: 5px;
    transition: 0.2s;
    font-weight: 500;
  }

  &__notif {
    .loader {
      left: 47%;
    }
  }

  &__button:active {
    font-size: 13px;
  }

  &__button:hover {
    background-color: #4472bc;
  }

  &__button:nth-child(n + 2) {
    margin-left: 50px;
  }
}

.support {
  background-color: #7fcd51;
}

.denied {
  background-color: #f26065;
}

@media screen and (max-width: 1199.98px) {
  .current-event {
    &__name {
      font-size: 20px;
    }

    &__description {
      font-size: 15px;
    }
  }
}

@media screen and (max-width: 991.98px) {
  .current-event {
    width: 840px;
  }

  .support,
  .denied {
    padding: 15px 30px;
  }
}

@media screen and (max-width: 767.98px) {
  .current-event {
    width: 690px;
  }

  .support,
  .denied {
    font-size: 12px;
    padding: 10px 15px;
    width: 190px;
  }
}

@media screen and (max-width: 575.98px) {
  .current-event {
    width: 480px;

    &__button:nth-child(n + 2) {
      margin-left: 25px;
    }
  }
}

@media screen and (max-width: 420px) {
  .current-event {
    width: 400px;

    &__buttons {
      flex-direction: column;
      align-items: center;
    }

    &__button:nth-child(n + 2) {
      margin-left: 0;
      margin-top: 15px;
    }
  }
}
