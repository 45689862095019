.accepted-modal {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 13px 40px;
    border-radius: 10px;
    background-color: #e7e7e7;
    margin-top: -57px;
    animation: ani 2s forwards;
  }

  &__title {
    font-size: 18px;
    color: #000;
  }
}

@keyframes ani {
  0% {
    margin-top: -58px;
  }
  20% {
    margin-top: 0px;
  }
  80% {
    margin-top: 0px;
  }
  100% {
    margin-top: -58px;
  }
}
