.tooltip {
  width: max-content;
  color: #ffffff;
  margin: 5px 0 0 10px;
  img {
    width: 20px;
  }

  &__context {
    position: absolute;
    width: 300px;
    padding: 10px 0;
    border-radius: 5px;
    background-color: #5c5c5c;
  }
}

.tooltip:hover {
  cursor: pointer;
}
